<template>
  <div :class="theme" style="height: 100vh; overflow: auto">
    <div style="padding: 20px">
      <h2>模型树App更新日志</h2>
      <p>本次更新内容涉及模块：</p>
      <p>包括整体UI，logo升级，编辑器升级，导图升级，目录升级等。</p>
      <p>详细说明：</p>
      <p>1.整体UI升级</p>
      <p>1.1 新版二/三种主题-黑白灰</p>
      <p>1.2 logo和整体样式更新</p>
      <p>2.新版编辑器</p>
      <p>2.1 优化粗体、重要、非常重要</p>
      <p>2.2 整体性能优化提升</p>
      <p>3.导图升级</p>
      <p>3.1 导图加载性能和优化</p>
      <p>3.2 导图下载功能优化</p>
      <p>3.3 导图内存量打开方式</p>
      <p>3.4 iPad右键编辑和详情功能</p>
      <p>4.目录升级</p>
      <p>4.1 增加屏幕可展示层级</p>
      <p>4.2 增加节点重点标识</p>
      <p>5.其他优化</p>
      <p>5.1 累计解决异常问题20+</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      theme: "light",
    };
  },
  mounted() {
    if (this.$route.params.theme == "light") {
      this.theme = "light";
      document.querySelector("body").removeAttribute("style");
    } else if (
      this.$route.params.theme == "dark" ||
      this.$root.config.theme.includes("dark")
    ) {
      this.theme = "dark";
      document
        .querySelector("body")
        .setAttribute("style", "background:#101218");
    }
  },
  beforeUnmount() {
    document.querySelector("body").removeAttribute("style");
  },
};
</script>
<style>
.dark {
  color: #ebebeb;
  background: #101218;
}
</style>
